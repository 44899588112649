@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap');

@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Book.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src: url('./CircularStd-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
