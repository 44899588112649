@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&display=swap);
.hooks-main > svg {
  display: none;
}

.hooks-main > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  /* box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75); */
  opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
  width: 180px;
  height: 180px;
  background: #5438dc;
}

.hooks-main > div:nth-child(2) {
  width: 250px;
  height: 250px;
  background: #357ded;
}

.hooks-main > div:nth-child(3) {
  width: 150px;
  height: 150px;
  background: #56eef4;
}
.hooks-main > div:nth-child(4) {
  width: 300px;
  height: 300px;
  background: #32e875;
}
.hooks-main > div:nth-child(5) {
  width: 200px;
  height: 200px;
  background: #d56062;
}

.hooks-main > div::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background: rgba(255, 255, 255, 0.8); */
}

.hooks-main > div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main > div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-filter: url('#goo');
          filter: url('#goo');
  overflow: hidden;
}

@font-face {
  font-family: 'CircularStd';
  src: url(/static/media/CircularStd-Book.bfc89dcf.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src: url(/static/media/CircularStd-Bold.949a2720.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src: url(/static/media/CircularStd-Black.8dedb92a.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

